import { defineStore } from 'pinia'
import axios from 'axios'
import Cookies from 'js-cookie'
import { url } from '@/config/http'
import { http } from '@/config'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
    state: () => ({
        cookies:
            Cookies.get('user') !== undefined
                ? JSON.parse(Cookies.get('user'))
                : undefined,
        loggedIn: '',
        token: '',
        user: '',
        form: {
            nama: '',
            user: '',
            email: '',
            role: '',
            password: '',
        },
        validate: '',
        returnUrl: '',
    }),
    actions: {
        async login(request) {
            // await axios.get(url + 'http://localhost/simpeg/sanctum/csrf-cookie')
            const user = await axios.post(url + '/login/', {
                user: request.user,
                password: request.password,
                device_name: 'web',
            })

            return user
        },

        async logout() {
            this.user = null
            this.token = null

            let myToken = this.getToken()

            await axios.delete(url + '/logout', {
                headers: {
                    Authorization: 'Bearer ' + myToken,
                    Accept: 'application/json',
                },
                withCredentials: true,
            })
            Cookies.remove('user')

            return true
        },

        removeCookies() {
            return Cookies.remove('user')
        },

        resetValidation() {
            this.validate = ''
        },

        setValidation(validation) {
            this.validate = validation
        },

        resetForm() {
            this.form.email = ''
            this.form.password = ''
        },

        getLoggedIn() {
            this.loggedIn =
                this.cookies === undefined ? false : this.cookies?.loggedIn

            return this.loggedIn
        },

        getToken() {
            this.token = this.cookies == undefined ? '' : this.cookies?.token

            return this.token
        },

        getUser() {
            this.user = this.cookies == undefined ? {} : this.cookies?.data

            return this.user
        },

        setCookies(cookies) {
            this.cookies = cookies
            this.getLoggedIn()
            this.getToken()
            this.getUser()
        },
    },
})
