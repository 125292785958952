<template>
    <CHeader position="sticky">
        <CContainer fluid>
            <CHeaderToggler class="ps-1" @click="toggleSidebar">
                <CIcon icon="cil-menu" size="lg" />
            </CHeaderToggler>
            <CHeaderBrand class="mx-auto d-lg-none">
                <h4>Apotik Basmah Ali</h4>
            </CHeaderBrand>
            <CHeaderNav>
                <AppHeaderDropdownAccnt />
            </CHeaderNav>
        </CContainer>
        <CHeaderDivider />
        <CContainer fluid>
            <AppBreadcrumb />
            <CButton
                v-if="!superAdmin && !pelanggan"
                color="info"
                @click="setorSession"
            >
                <CIcon :icon="cilCheckCircle" class="me-1" />
                SETOR
            </CButton>
        </CContainer>
    </CHeader>
    <CModal
        v-if="!superAdmin"
        :visible="visible"
        @close="
            () => {
                visible = false
            }
        "
    >
        <CModalHeader>
            <CModalTitle>Setor Sesi</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CRow>
                <CCol>
                    <div class="mb-1">
                        <CFormInput
                            type="text"
                            label="Total Tagihan"
                            :value="format(ttl_tagihan)"
                            disabled
                        />
                    </div>
                </CCol>
                <CCol>
                    <div class="mb-1">
                        <CFormInput
                            type="text"
                            label="Bayar"
                            :value="bayar"
                            @input="bayarInput"
                        />
                    </div>
                </CCol>
            </CRow>
        </CModalBody>
        <CModalFooter>
            <CButton color="primary" @click="onSubmit">Simpan</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { useSidebarStore } from '@/store'
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { mapActions } from 'pinia'
import { cilCheckCircle } from '@coreui/icons'
import { numberFormat } from '@/utils/format'
import { useAuthStore } from '@/store/auth'
import { http } from '@/config'
import { useToastStore } from '@/store/toast'
import Cookies from 'js-cookie'
import { SUPER_ADMIN } from '@/utils/auth'
import { formatInput, parseFormatInput } from '@/utils/format'

export default {
    name: 'AppHeader',
    components: {
        AppBreadcrumb,
        AppHeaderDropdownAccnt,
    },
    data() {
        return {
            logo,
            cilCheckCircle,
            visible: false,
            id: 0,
            ttl_tagihan: 0,
            bayar: '',
        }
    },
    computed: {
        superAdmin() {
            return useAuthStore().getUser().role === SUPER_ADMIN
        },
        pelanggan() {
            return useAuthStore().getUser().role === 'PELANGGAN'
        },
    },
    methods: {
        ...mapActions(useSidebarStore, ['toggleSidebar']),
        format(val) {
            return numberFormat(val)
        },
        updateSession() {
            if (confirm('Apakah ingin mengakhiri session ?')) {
                alert('test')
            }
        },
        bayarInput(event) {
            let bayar = event.target.value
            this.bayar = formatInput(bayar)
        },
        async setorSession() {
            await http
                .get('/setor/total')
                .then((resp) => {
                    let setor = resp.data.data
                    this.id = setor.userShift.id
                    this.ttl_tagihan = setor.ttl_tagihan
                    this.visible = true
                })
                .catch((err) => {
                    err
                    useToastStore().showToast({
                        show: true,
                        classType: 'bg-danger',
                        title: 'Gagal',
                        msg: err?.response?.data?.message,
                    })
                    this.visible = false
                })
        },
        async onSubmit() {
            await http
                .put(`/setor/${this.id}/update`, {
                    ttl_tagihan: this.ttl_tagihan,
                    jml_setor: parseFormatInput(this.bayar),
                })
                .then((resp) => {
                    this.visible = false
                    Cookies.remove('user')
                    this.$router.go('/login')
                })
                .catch((err) => {
                    useToastStore().showToast({
                        show: true,
                        classType: 'bg-danger',
                        title: 'Gagal',
                        msg: err?.response.data.message,
                    })
                    this.visible = true
                })
        },
    },
}
</script>
