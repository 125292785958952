<template>
    <CFooter>
        <div>
            <a href="https://instagram.com/@farislucky" target="_blank">
                Aplikasi Apotek
            </a>
            <strong class="ms-1">&copy; {{ new Date().getFullYear() }}</strong>
        </div>
        <div class="ms-auto">
            <span class="me-1" target="_blank">Develop by</span>
            <a href="#">
                <strong>Salman</strong>
            </a>
        </div>
    </CFooter>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>
