<template>
    <div style="position: fixed; top: 50%; left: 50%; z-index: 9999">
        <div class="p-2 rounded border bg-white">
            <CSpinner color="dark" />
        </div>
    </div>
</template>
<script>
export default {}
</script>
