import { useAuthStore } from '@/store/auth'

const SUPER_ADMIN = 'SUPER_ADMIN'
const ADMIN = 'ADMIN'
const KASIR = 'KASIR'

const kasir = (user) => {
    return useAuthStore().role === KASIR
}

export { kasir, ADMIN, SUPER_ADMIN }
