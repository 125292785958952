<style scoped>
.role {
    display: block;
    font-size: 10px !important;
}
</style>
<template>
    <CDropdown variant="nav-item">
        <CDropdownToggle
            placement="bottom-end"
            class="py-0 text-center"
            :caret="false"
            href="javascript: void(0)"
        >
            <strong class="border-bottom">{{ nama }}</strong>
            <span class="role">{{ role }}</span>
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Pengaturan
            </CDropdownHeader>
            <CDropdownItem @click="forceReload" style="cursor: pointer">
                <CIcon :icon="cilSync" /> Refresh
            </CDropdownItem>
            <CDropdownItem @click="onLogout" style="cursor: pointer">
                <CIcon icon="cil-lock-locked" /> Logout
            </CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
</template>

<script>
import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'
import { cilSync } from '@coreui/icons'
export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
        const router = useRouter()
        const user = useAuthStore().getUser()
        const nama = user.name.toUpperCase()
        const role = user.role

        const onLogout = () => {
            useAuthStore()
                .logout()
                .then((resp) => {
                    useAuthStore().removeCookies()
                    router.go('/login')
                })
                .catch((err) => {
                    err
                })
        }
        const forceReload = () => {
            window.location.reload(true)
        }

        return {
            itemsCount: 42,
            onLogout: onLogout,
            forceReload: forceReload,
            cilSync,
            nama,
            role,
        }
    },
}
</script>
